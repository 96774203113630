import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import * as React from 'react';
import Map from 'react-map-gl';
import { useState, useEffect, useMemo, } from 'react'
import { v4 as uuidv4 } from 'uuid';
import DeckGL from '@deck.gl/react';
import {ColumnLayer} from '@deck.gl/layers';
import { Slider, 
         Table, 
         TableBody, 
         TableCell, 
         TableHead, 
         TableRow,
         TablePagination,
         TableContainer,} from '@mui/material';
import { max, group, mean, sum, } from 'd3';


function App() {
  const [state, setState] = useState([]);
  const florence1561data = useMemo(()=> {
    return [...state];
  }, [state]) 

  // const mapStyle = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json'
  // const mapStyle = 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json'
  const mapStyle = `mapbox://styles/nkmwicz/cl0tn8k16000r14s2xbgesv75`
  const getData = async () =>{
    const response = await fetch('https://raw.githubusercontent.com/nkmwicz/misc/master/data/florenceData/florence1561v3.json');
    const json = await response.json();
    setState(json);
    setData(json)
  }
  useEffect(() =>{
    getData();
  }, []);
  // console.log(florence1561data);

  const REACT_APP_MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;


  const maxRent = max(state.map(d=>d.attributes.rents_in_scudi));
  const maxPop = max(state.map(d => {
    const totalPop = d.attributes.F && d.attributes.M 
                     ?
                     d.attributes.M + d.attributes.F
                     :
                     d.attributes.F && !d.attributes.M 
                     ? 
                     d.attributes.F
                     :
                     !d.attributes.F && d.attributes.M 
                     ? 
                     d.attributes.M
                     : 
                     0
    return totalPop
  }))

  const [hoverInfo, setHoverInfo] = useState({});
  const [value, setValue] = useState([]);
  const [popValue, setPopValue] = useState([]);
  const [data, setData] = useState([]);
  const [population, setPopulation] = useState(true);
  const [tableState, setTableState] = useState(true);
  const [totalPopulation, setTotalPopulation] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [slicedGroupedOwners, setSlicedGroupedOwners] = useState([])
  const [landing, setLanding] = useState(true);
  const [legend, setLegend] = useState(false);

  useEffect(() => {
    const totalPopArray = []
    data.forEach(d => {
      const pop = d.attributes.F && d.attributes.M 
                  ?
                  d.attributes.M + d.attributes.F
                  :
                  d.attributes.F && !d.attributes.M 
                  ? 
                  d.attributes.F
                  :
                  !d.attributes.F && d.attributes.M 
                  ? 
                  d.attributes.M
                  : 
                  0;
      totalPopArray.push(pop);
    })
    const totalPop = sum(totalPopArray);
    setTotalPopulation(totalPop)
  }, [data])

  const groupedByOwner = useMemo(() => {
    const grouped = Array.from(
      group(state, 
      d => d.attributes.Owner)
    );
    const buildingsOwned = grouped.map(d => {
      const owner = d[0];
      const numberOwned = d[1];
      const attrs = [];
      d[1].forEach(a => attrs.push(a));
      const rents = d[1].map(entry => parseInt(entry.attributes.rents_scudi));
      const avgRents = mean(rents);
      const meanRents = avgRents === undefined ? null : avgRents;
      return (
        {owner: owner, buildings: numberOwned.length, avgRent: meanRents, rents: rents,  attributes: attrs}
      )
    });

    const filtered = buildingsOwned.filter(d => d.buildings > 1)
    return filtered.sort((a,b) => b.buildings - a.buildings)
  }, [state]);

  useEffect(() => {
  const newArray = groupedByOwner.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  setSlicedGroupedOwners(newArray)
  }, [groupedByOwner, page, rowsPerPage]);

  const handleChange = (e, newValue) => {
    if (population) {
      setPopValue(newValue)
    }
    if (!population){
      setValue(newValue);
    }
  }

  const handleCommit = (e, nextValue) => {
    if(population) {
      const filteredPop = florence1561data.filter(d => {
        const totalPop = d.attributes.F && d.attributes.M 
                          ?
                          d.attributes.M + d.attributes.F
                          :
                          d.attributes.F && !d.attributes.M 
                          ? 
                          d.attributes.F
                          :
                          !d.attributes.F && d.attributes.M 
                          ? 
                          d.attributes.M
                          : 
                          0;
        if (totalPop >= popValue[0] && totalPop <= popValue[1]) {
         return d
        }
        return null
      })
      setData(filteredPop)
    }
    if (!population) {
      const filteredRents = florence1561data.filter(d => d.attributes.rents_in_scudi >= value[0] && d.attributes.rents_in_scudi <= value[1]);
      setData(filteredRents);
    }
  }

  useEffect(()=>{
    setValue([0, maxRent === undefined ? maxRent : maxRent.toFixed(0)])
  }, [maxRent, state])

  useEffect(()=>{
    setPopValue([0, maxPop])
  }, [maxPop, state])


  const layer = useMemo(() => {
    return (
      new ColumnLayer({
        id: 'column-layer',
        data: data,
        diskResolution: 12,
        radius: 3,
        filled: true,
        extruded: true,
        pickable: true,
        elevationScale: 5000,
        autoHighlight: true,
        highlightColor: [45,85,255,255],
        getPosition: d => d.geometry.coordinates,
        // getFillColor: d => [ d.attributes.rents_in_scudi * 10, 120, 0, 255], // measures property values
        getFillColor: population ? (d) => (d.attributes.M !==0 && d.attributes.M === d.attributes.F ? [255,36,255,255] : d.attributes.F === 0 && d.attributes.M !== 0 ? [36,36,255,255] : d.attributes.F === 0 && d.attributes.M === 0 ? [0,0,0,255] : d.attributes.F !== 0 && d.attributes.M ===0? [255,36,36,255] : d.attributes.F + d.attributes.M < 10 ? [ d.attributes.F * 70, 0, d.attributes.M * 70, 255] : [d.attributes.F * 35, 0, d.attributes.M * 35, 255]) : (d) => (d.attributes.rents_in_scudi < 12 ? [0,168,0,255] : d.attributes.rents_in_scudi >= 12 && d.attributes.rents_in_scudi < 29 ? [255,255,36,255] : d.attributes.rents_in_scudi >= 29 && d.attributes.rents_in_scudi< 46? [214,139,0,255] : d.attributes.rents_in_scudi >= 46 && d.attributes.rents_in_scudi <= 63 ? [255, 82,82,255] : [168,0,0,255]),
        getLineColor: d => [100, 0, 0],
        // getElevation: d => d.attributes.rents_in_scudi ? d.attributes.rents_in_scudi *.0007 : 0, // measures property values in rents
        getElevation: population? (d => d.attributes.F && d.attributes.M ? (d.attributes.M + d.attributes.F) *.001 : d.attributes.F && !d.attributes.M ? d.attributes.F *.001 : !d.attributes.F && d.attributes.M ? d.attributes.M * .001: 0) : (d => d.attributes.rents_in_scudi ? d.attributes.rents_in_scudi *.0007 : 0), // measures population variance M/F
      })
    )
  }, [data, population])

function renderPopup(info) {
  const {object, x, y} = info;
  if (info.object) {
    return (
      <div className="tooltip interactive" style={{left:x + 10, top:y - (350/2)}}>
        <h4 className="center-text ttheader">Owner:<br/>{object.attributes.Owner}</h4> <hr/>
        <p><strong>Rents in Scudi:</strong> {object.attributes.rents_in_scudi ? object.attributes.rents_in_scudi.toFixed(0): object.attributes.rents_in_scudi}</p>
        {object.attributes.rents_lire? <p><strong>Rents in Lire:</strong> {object.attributes.rents_lire}</p> : null}
        <p><strong>Street:</strong> {object.attributes.Street}</p>
        <p><strong>Quarter:</strong> {object.attributes.Quartiere}</p>
        <p><strong>Owned/Rented:</strong> {object.attributes.Contract_Main}</p>
        <p><strong># Residents:</strong> {object.attributes.F? object.attributes.F: 0} F, {object.attributes.M ? object.attributes.M : 0} M</p>
        <p><strong>Resident Names:</strong> {object.attributes.Resident_1 ? `${object.attributes.Resident_1}` : null}{object.attributes.Resident_2 ? `; ${object.attributes.Resident_2}` : null}{object.attributes.Resident_3 ? `; ${object.attributes.Resident_3}` : null}</p>
      </div>
    )
  }
  if(!object) {
    return (
      null
    )
  }
}


const hidePopup = () => {
  setHoverInfo({});
}

const expandPopup = info => {
  if (info.picked) {
    setHoverInfo(info)
  } else {
    setHoverInfo({})
  }
}
// const handleTableClick = (e) => {
//   console.log(e.target.textContent)
//   // const filterOwnerName = florence1561data.filter(d => d.attributes.Owner? d.attributes.Owner.indexOf(e.target.textContent) !== -1 : null );
//   const filterOwnerName = florence1561data.filter(d => d.attributes.Owner? d.attributes.Owner === e.target.textContent : null );
//   setData(filterOwnerName)
// }

const handleSearch = (e) => {
  const filteredOwners = florence1561data.filter(d => d.attributes.Owner? d.attributes.Owner.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 : null);
  setData(filteredOwners)
}

const INITIAL_VIEW_STATE = {
  longitude: 11.26,
  latitude: 43.78,
  zoom: 14,
  pitch: 35,
  bearing: 0
};



function handleTableBtnClick(e) {
  if (e) {
    setTableState(!tableState)
  }
}

function handlePopulationBtnClick(e) {
  if (e) {
    setPopulation(!population);
    const datas = [...data]
    setData(datas)
  }
}

const [selected, setSelected] = useState([]);
const [headerHighlight, setHeaderHighlight] = useState(false);

const handletableRowClick = (event, name) => {
  console.log('handletableRowClick');
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1),
    );
  }

  setSelected(newSelected);

    // const filterOwnerName = florence1561data.filter((d) => selected.map(a => d.attributes.Owner? d.attributes.Owner === a : null ));
    // setData(filterOwnerName);
};

const handleSelectAllClick = (event) => {
  if (event){
    setHeaderHighlight(!headerHighlight);
  }
};

useEffect(() => {
  if (headerHighlight) {
    const newSelecteds = groupedByOwner.map((n) => n.owner);
    setSelected(newSelecteds);
    return;
  }
  setSelected([]);
}, [headerHighlight, groupedByOwner]);

useEffect(() => {
  if (selected.length > 0) {
    const filterOwnerName = florence1561data.filter(d => d.attributes.Owner? selected.indexOf(d.attributes.Owner) !== -1: null);
    setData(filterOwnerName);
  }
  if(selected.length === 0) {
    setData(florence1561data)
  }
}, [florence1561data, selected])

function changeLegend(e) {
  if (e) {
    setLegend(!legend)
  }
}

function closeLanding(e) {
  if (e) {
    setLanding(false);
  }
}

const MyTable = ({handletableRowClick}) => {
  function isSelected(name) {
    return selected.indexOf(name) !== -1;
  }
  
  const handleChangePage = (event, newPage) => {
    console.log('handlChagnepage');
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    console.log('rows per page');
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
  <>
  <div className="rightInfo">
        <h2 style={{textAlign: 'center'}}>Property Owners of Multiple Properties</h2><br/><p className="shift">*click table row to filter map by owner<br/> click table header to select/deselect all rows*</p><hr/>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow onClick={handleSelectAllClick}
                        className={headerHighlight? 'headerHighlight' : 'pointer'}>
                <TableCell>Owner</TableCell>
                <TableCell align='right'># Properties</TableCell>
                <TableCell align='right'>Avg Rent</TableCell>
                <TableCell align='right'>Total Residents</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slicedGroupedOwners.map((d, index)=> {

                const isItemSelected = isSelected(d.owner);

                const peopleArray = [];
                d.attributes.forEach(a => {
                  const totalRes = a.attributes.F && a.attributes.M 
                  ?
                  a.attributes.M + a.attributes.F
                  :
                  a.attributes.F && !a.attributes.M 
                  ? 
                  a.attributes.F
                  :
                  !a.attributes.F && a.attributes.M 
                  ? 
                  a.attributes.M
                  : 
                  0;
                  peopleArray.push(totalRes);
                })
                const totalPeople = sum(peopleArray)
                // console.log(peopleArray)

                return (
                  <TableRow
                    key={uuidv4()}
                    selected={isItemSelected}
                    style={{cursor: 'pointer'}}
                    onClick={(e) => handletableRowClick(e, d.owner)}
                    >
                      <TableCell >{d.owner}</TableCell>
                      <TableCell align='right'>{d.buildings}</TableCell>
                      <TableCell align='right'>{d.avgRent === undefined ? d.avgRent : Number(d.avgRent).toFixed(0)}</TableCell>
                      <TableCell align='right'>{totalPeople}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 35, 50]}
          component='div'
          count={groupedByOwner.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          >

        </TablePagination>

      </div>  
  </>
  )
}


if (state.length === 0){
  return <div>Loading...</div>
}
  // console.log

  return (
    <>

      <canvas className={'myCanvas'} id={'canvas'}></canvas>

      <DeckGL 
        layers={layer}
        initialViewState={INITIAL_VIEW_STATE}
        controller={true}
        onViewStateChange={hidePopup}
        // onHover={expandPopup}
        onHover={expandPopup}
      >
        <Map 
                    reuseMaps 
                    mapStyle={mapStyle}
                    preventStyleDiffing={true}
                    mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}

            ></Map>
        {renderPopup(hoverInfo)}
        <div className="footer-div">
          <div className="title"><p className="shift">Hold shift to pan and rotate</p>{population ? (<h3 className='text-center'>Florence (1561)<br/>Population by property<br/></h3>) : (<h3 className='text-center'>Florence (1561): Property Values <br/> based on rents normalized to Scudi </h3>)}<p className="text-center smaller">Population: {totalPopulation} <br/>Properties: {data.length}</p><p className="text-center">Data source: <a href="https://decima-map.net/" target="_blank" rel="noopener noreferrer">The DECIMA Project</a></p></div>
        </div>
   
      </DeckGL>
      <div className="rangeSlider">
          <Slider
            getAriaLabel={population? () => 'Population range' : () => 'Property values range'}
            value={population? popValue : value}
            onChangeCommitted={handleCommit}
            onChange={handleChange}
            color='secondary'
            sx={{
              '& .MuiSlider-thumb': {
                backgroundColor: 'white',
                border: 'solid black 1px',
              },
              '& .MuiSlider-track': {
                backgroundColor: 'gray',
              },
              '& .MuiSlider-rail': {
                backgroundColor: 'gray',
              }
            }}
            orientation='vertical'
            valueLabelDisplay="auto"
            getAriaValueText={() => "valuetext"}
            max={population ? maxPop : maxRent}
          />
      </div>
      <div className="leftLabel">{population? `${popValue[1]} people` : `${value[1]} scudi`}</div>
      <div className="rightLabel">{population? `${popValue[0]} people` : `${value[0]} scudi`}</div>
      <button className="btn table-btn" onClick={handleTableBtnClick}>{tableState? 'Open Table' : 'Close Table'}</button>
      <button className="btn map-btn" onClick={handlePopulationBtnClick}>{population? 'View Rents': 'View Pop.'}</button>
      <input className="search" type="text" placeholder='search owner' onKeyUp={handleSearch}/>

      {legend? 
        population? <div className="legend"><p className="text-center">Population</p><span className="close-landing" onClick={changeLegend}>✖</span><span className="dot blue"></span> : primarily males<br/><span className="dot red"></span> : primarily females<br/><span className="dot pink"></span> : male/female mix</div> : <div className="legend"><span className="close-landing" onClick={changeLegend}>✖</span><p className="text-center">Rents</p><p><span className="dot green"></span> : less than 12 scudi <br/><span className="dot yellow"></span> : between 29 and 45 scudi <br/><span className="dot orange"></span> : between 46 and 62 scudi<br/><span className="dot red"></span> : more than 63 scudi</p></div>
      :
      <button className="btn legend-btn" onClick={changeLegend}>legend</button>}
    

      {!tableState? <MyTable 
        // groupedByOwner={groupedByOwner}
                              //  rowsPerPage={rowsPerPage}
                              //  page={page}
                              //  handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage}
                               
                               handletableRowClick={handletableRowClick}/> : null} 
      {landing?
        <div className="landing-page">
          <p>
          <h4 className="text-center">
            Welcome to a 3-d representation of Florentine properties in 1561.
          </h4> 
            The data on this map comes from the <a href="https://decima-map.net" target="_blank" rel="noopener noreferrer">DECIMA project</a> that has been collected from the Florentine census. It is an exhaustive and fantastic dataset. The DEMICA project has produced numerous maps using ArcGIS, and this project is not meant to replace it by any means. Instead, it is meant as an example of the power JavaScript has to render complex visualizations in interactive ways. 
          </p>
          <p>
            <strong>Interactivity:</strong> This page is fully interactive. Shift + left click will pan and rotate the map. Change the visualization from a representation of population numbers to rent values. The Open Table button will represent the data on the map in a tabular format. Clicking on a table row will filter the data on the map to show only the properties on the map owned by that individual or corporate entity. Finally, the range slider in the top left will filter the data on the map by population per property or rent value per property.
          </p>
          <p>
            <strong>Technology:</strong> This project uses MapBox-GL to render the map and Deck.GL to render the data. Both JavaScript libraries use GPU-accelerated processing to make it possible to effortlessly render almost 9,000 datapoints in 3-dimensional space. Deck.GL has examples in its documentation of rendering nearly one million datapoints. The project is built with React to produce a reactive environment in which the state of the map can be easily manipulated. Finally, the project is also mobile friendly.
          </p>
          <p>Happy Exploring!</p>
          <span className='close-landing' onClick={closeLanding}>✖</span> 
        </div> 
        : null} 
      </>
  );
}

export default App;
